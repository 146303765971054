import mixins from '@@/core/mixins/mixins'
import productRequest from '@@/core/components/product/classes/productRequest'
import ProductFaker from "@@/core/components/product/faker/ProductFaker";
import productModelToData from '@@/core/components/product/classes/productModelToData'
import ProductInformation from '@@/core/components/product/ProductInformation/ProductInformation'
import ProductSizeChart from "@@/core/components/product/ProductSizeChart/ProductSizeChart";
import ProductImages from "@@/core/components/product/ProductImages/ProductImages";
import ProductShippingConfig from "@@/core/components/product/small-cards/ProductShippingConfig/ProductShippingConfig";
import ProductShowConfig from "@@/core/components/product/small-cards/ProductShowConfig/ProductShowConfig";
import ProductFlashConfig from "@@/core/components/product/small-cards/ProductFlashConfig/ProductFlashConfig";
import ProductOtherConfig from "@@/core/components/product/small-cards/ProductOtherConfig/ProductOtherConfig";
import ProductSeo from "@@/core/components/product/ProductSeo/ProductSeo";
import ProductPublishing from "@@/core/components/product/small-cards/ProductPublishing/ProductPublishing";
import ProductSpecifications from "@@/core/components/product/ProductSpecifications/ProductSpecifications";
import ProductPricing from "@@/core/components/product/small-cards/ProductPricing/ProductPricing";
import ProductDescription from "@@/core/components/product/ProductDescription/ProductDescription";
import ProductVarieties from "@@/core/components/product/ProductVarieties/ProductVarieties";
import Loading from "@@/core/components/shared/Loading/Loading";
import configProvider from "@@/core/configProvider";
import {BRow, BCol, BButton} from "bootstrap-vue";
import ProductVideo from "@@/core/components/product/ProductVideo/ProductVideo";

const defaultSizeChartFactory = () => {
  return {
    title: '',
    chart: [
      ['', '', ''],
      ['', '', ''],
      ['', '', ''],
    ],
    type_id: null
  }
}

export default {
  name: "Product",
  mixins:[mixins],
  configProvider,
  components: {
    BRow, BCol, BButton, // vue-bootstrap
    ProductOtherConfig,
    ProductSeo,
    ProductPublishing,
    ProductSpecifications,
    ProductPricing,
    ProductDescription,
    ProductVarieties,
    Loading,
    ProductFlashConfig,
    ProductShowConfig,
    ProductShippingConfig,
    ProductSizeChart,
    ProductImages,
    ProductInformation,
    ProductVideo,

  },
  props: {
    id: String
  },
  data() {
    return {
      mode: 'create',
      edit: null,
      disabled: false,
      loading: true,
      productInformation: {
        title: '',
        categories: [],
        brand: null,
        unit: null,
        barcode: '',
        sku: '',
        tags: [],
        quantity: ''
      },
      productVideo: {
        video: null,
        cover: null,
      },
      editProductVideo: {
        video: null,
        cover: null,
      },
      productGifts: {
        gifts: [

        ],
        shouldMerge: false,
      },
      productPricing: {
        unitPrice: '',
        purchasePrice: '',
        discountType: 'none',
        discount: '',
        discountUntil: ''
      },
      productDescription: {
        shortDescription: '',
        description: '',
      },
      productImages: [],
      productSizeCharts: [],
      selectedSpecs: [],
      productVarieties: {
        colors: [],
        attributes: [],
        varieties: [],
        selected_attribute_values: [],
      },
      gifts: [],
      attributes: [],
      categories: [],
      units: [],
      tags: [],
      colors: [],
      sizeChartTypes: [],
      publicSpecifications: [],
      productPublishing: {
        status: configProvider.get('product.defaultStatus'),
        publishedAt: moment().format('YYYY/MM/D HH:mm'),
        scheduling: false
      },
      productShow: {
        showQuantity: false,
        chargeable: configProvider.get('product.defaultChargeable')
      },
      productSeo: {
        metaTitle: '',
        metaDescription: '',
        slug: '',
      },
      productOther: {
        lowStockQuantityWarning: configProvider.get('product.defaultQuantityWarning'),
        listenCharge: true
      }
    }
  },
  computed: {
    selectedCategories() {
      return this.getSelectedCategories(this.categories)
    }
  },
  created() {
    this.init();
  },
  beforeDestroy(){
    document.removeEventListener('keypress', this.onKeyPress)
  },
  watch: {
    'productVarieties.attributes'(newVal) {
      // console.log(newVal)
      // console.trace()
    }
  },
  methods: {
    init() {
      if (this.id) {
        this.mode = 'edit'
      }
      this.loadInitData()
      document.removeEventListener('keypress', this.onKeyPress)
      document.addEventListener('keypress', this.onKeyPress)
    },
    onKeyPress(e) {
      if (e.target && (e.target.tagName !== 'BODY')) {
        return;
      }
      if (e.keyCode === 81 && e.shiftKey) {
        this.resetData()
      }
      if (e.keyCode === 87 && e.shiftKey) {
        this.generateFakeData()
      }
    },
    resetData() {
      let initialData = this.$options.data.apply(this);
      for (let [index] of Object.entries(this.$data)) {
        this.$data[index] = initialData[index]
      }
      this.init();
    },
    generateFakeData() {
      let faker = new ProductFaker();
      this.productInformation.title = faker.getTitle()
      this.productInformation.quantity = Math.floor(Math.random() * 10);
      this.productDescription.description = faker.getDescriptions()
      this.productDescription.shortDescription = faker.getShortDescriptions()
      this.productInformation.barcode = faker.getBarcode()
      this.productInformation.sku = faker.getSku()
      if (this.categories.length) {
        this.productInformation.categories = [this.categories[0].id];
        if (this.categories[0].brands.length) {
          this.productInformation.brand = this.categories[0].brands[0].id
        }
        this.productInformation.unit = this.units.length ? this.units[0].id : '';
        this.productPricing.unitPrice = faker.getPrice();
        this.productPricing.purchasePrice = faker.getPrice()
        this.productPricing.discountType = faker.getDiscountType()
        this.productPricing.discount = faker.getDiscount(this.productPricing.unitPrice, this.productPricing.discountType)
        this.productPublishing.status = faker.getStatus()
      }
    },
    getSelectedCategories(categories) {
      let selectedCats = categories.filter(cat => {
        return this.productInformation.categories.includes(cat.id)
      })
      categories.forEach(cat => {
        selectedCats = [...selectedCats, ...this.getSelectedCategories(cat.children)];
      })

      return selectedCats
    },
    async submit() {
      this.disabled = true
      let productVarietiesComponent = this.$refs.productVarieties;
      let request = new productRequest(this.$data, productVarietiesComponent);
      let data = request.generateRequestData();
      try {
        let response;
        if (this.mode === 'create') {
          if (
            data.product.video != null &&
            data.product.video.includes("farshebrahimi.ir/storage")
          ) {
            data.product.video = this.editProductVideo.video.id;
          }
          if (
            data.product.video_cover != null &&
            data.product.video_cover.includes("atlasmode.ir/storage")
          ) {
            data.product.video_cover = this.editProductVideo.cover.id;
          }
          response = await this.$axios.post('admin/products', data);
        } else {
          response = await this.$axios.put('admin/products/' + this.edit.id, data);
        }
        this.$root.notify(response.data.message, 'success');
        this.$router.push('/admin/products');
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
    async loadInitData() {
      try {
        if (this.mode === 'create') {
          const response = await this.$axios.get('admin/products/create');
          const data = response.data.data;
          this.assignInitData(data)
          this.loading = false;
        } else {
          const responseProduct = await this.$axios.get('admin/products/' + this.id, {
            params: {
              widgets: '["create_product"]'
            }
          });
          this.edit = responseProduct.data.data.product;
          this.assignInitData(responseProduct.data.data.widgets);
          this.$nextTick(() => {
            // خواندن دیتا از ای پی آی و قرار دادن در دیتا
            new productModelToData(this.edit, this.$data, this.$props)
            this.loading = false
          })
        }
      } catch (error) {
        this.$root.notify(error);
      }
    },
    assignInitData(data) {
      this.categories = data.categories;
      this.attributes = data.all_attributes
      this.units = data.units;
      if (data.units.length && !this.productInformation.unit) {
        this.productInformation.unit = data.units[0].id
      }
      this.tags = data.tags.map(tag => {
        tag.id = tag.name;
        return tag;
      })
      this.colors = data.colors;
      this.publicSpecifications = data.public_specifications;
      this.sizeChartTypes = data.size_chart_types;
      this.gifts = data.gifts;
    },
    addTag(tag) {
      let found = false;
      this.tags.forEach((item) => {
        if (item.name == tag.name) {
          found = true;
        }
      })
      if (!found) {
        this.tags.push(tag)
      }
    },
    addSizeChart() {
      this.productSizeCharts.push(defaultSizeChartFactory())
    }
  }
}
